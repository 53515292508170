import * as React from 'react'
import { graphql, navigate, type HeadFC, type PageProps } from 'gatsby'
import { HeaderAndFooterLayout } from '../../layouts/HeaderAndFooter'
import { css } from '../../../styled-system/css'
import SEO from '../../components/SEO'

const BlogPage: React.FC<PageProps> = ({ data }) => {
  const typedData = data as {
    allContentfulBlogs: {
      edges: {
        node: {
          type: any
          id: string
          images: { url: string }[]
          title: string
          createdAt: Date
          shortDescription: string
          cover: { url: string }
          slug: string
        }
      }[]
    }
  }
  const firstFlex = [
    typedData.allContentfulBlogs.edges[0],
    typedData.allContentfulBlogs.edges[1],
  ]
  const secondFlex = typedData.allContentfulBlogs.edges[0]
    ? typedData.allContentfulBlogs.edges.slice(0)
    : []

  return (
    <>
      <link rel="preload" href="./style/style.css" />
      <HeaderAndFooterLayout>
        <div
          className={css({
            display: 'flex',
            flexDir: 'column',
            gap: '20px',
            justifyContent: 'center',
            padding: '0 20px',
            width: '100%',
            margin: 'auto',
            '@media (max-width: 768px)': {
              margin: '1rem auto 0',
              gap: '0px',
            },
          })}
        >
          <h2
            className={css({
              fontSize: '2rem',
              fontWeight: '600',
              textAlign: 'center',
              color: '#594A2E',
              p: '1rem',
            })}
          >
            Les derniers articles
          </h2>

          <div
            className={css({
              display: 'flex',
              flexWrap: 'wrap',
              gap: '20px',
              marginTop: '0px',
              justifyContent: 'center',
              padding: '0 20px',
              '@media (max-width: 768px)': {
                display: 'none',
              },
            })}
          >
            {firstFlex.map(({ node }, index) => (
              <div
                className={css({
                  display: 'flex',
                  flexDirection: 'column',
                  cursor: 'pointer',
                  maxWidth: '600px',
                  width: '40%',
                  aspectRatio: '0.9',
                  borderRadius:
                    index === 0 ? '0px 50px 50px 0px' : '50px 0px 0px 50px',
                  overflow: 'hidden',
                  position: 'relative',
                  border: '4px solid #D9C58B',
                  transition: 'transform 0.2s',
                  '&:hover': {
                    transform: 'scale(1.02)',
                  },
                })}
                key={node.id}
                onClick={() =>
                  navigate(`${node.slug ? node.slug : node.title}`)
                }
              >
                <img
                  className={css({
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    zIndex: '-1',
                  })}
                  src={node.cover.url}
                  alt={node.title}
                />
                <div
                  className={css({
                    padding: '10px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '40%',
                    textShadow: '1px 1px 2px rgba(0, 0, 0, 0.25)',
                    textAlign: 'center',
                    width: '100%',
                    position: 'absolute',
                    top: '50%',
                  })}
                >
                  <h2
                    className={css({
                      fontSize: '2vw',
                      color: 'white',
                      margin: '0 0 10px',
                      _firstLetter : {
                        textTransform: 'uppercase'
                      }
                    })}
                  >
                    {node.type ? node.type : 'Type'}
                  </h2>
                  <p
                    className={`truncated_text_2l ${css({
                      width: '100%',
                      fontSize: '1.5vw',
                      color: 'white',
                      flexGrow: 1,
                    })}`}
                    title={node.shortDescription}
                  >
                    {node.shortDescription}
                  </p>
                  <span
                    className={css({
                      fontSize: '1.5vw',
                      color: 'white',
                      textAlign: 'center',
                    })}
                  >
                    {new Date(node.createdAt).toLocaleDateString()}
                  </span>
                </div>
              </div>
            ))}
          </div>
          {secondFlex && (
            <div
              className={css({
                display: 'flex',
                flexWrap: 'wrap',
                gap: '20px',
                margin: '75px auto 0',
                justifyContent: 'left',
                alignItems: 'left',
                width: '90%',
                padding: '0 20px',
                '@media (max-width: 768px)': {
                  marginTop: '0px,',
                },
              })}
            >
              {secondFlex.map(({ node }) => (
                <div
                  className={css({
                    width: '300px',
                    height: '350px',
                    position: 'relative',
                    cursor: 'pointer',
                    display: 'flex',
                    flexDir: 'column',
                    border: '#F2D98D solid 1px',
                    transition: 'transform 0.2s',
                    '&:hover': {
                      transform: 'scale(1.02)',
                    },
                    '@media (max-width: 768px)': {
                      width: '80%',
                      aspectRatio: '1',
                      height: 'auto',
                    },
                    justifyContent: 'flex-end',
                  })}
                  key={node.id}
                  onClick={() =>
                    navigate(`${node.slug ? node.slug : node.title}`)
                  }
                >
                  <img
                    className={css({
                      objectFit: 'cover',
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      zIndex: '-1',
                    })}
                    src={node.cover.url}
                    alt={node.title}
                  />
                  <h2
                    className={`truncated_text ${css({
                      textAlign: 'center',
                      width: '100%',
                      color: 'white',
                      alignContent: 'center',
                      height: '125px',
                      backgroundColor: 'rgba(217, 217, 217, 0.5)',
                      padding: '0 10px'
                    })}`}
                    title={node.shortDescription}>
                    {node.shortDescription ? node.shortDescription : ''}
                  </h2>
                </div>
              ))}
            </div>
          )}
        </div>
      </HeaderAndFooterLayout>
    </>
  )
}

export default BlogPage

export const query = graphql`
  query MyQuery {
    allContentfulBlogs {
      edges {
        node {
          type
          id
          title
          slug
          description {
            raw
          }
          images {
            url
            filename
          }
          shortDescription
          cover {
            url
          }
          createdAt
        }
      }
    }
  }
`

export const Head: HeadFC = () => (
  <SEO
    title="Blog Photographie - Conseils et Inspirations par Digit Universe"
    description="Découvrez le blog de Digit Universe à Lille pour des conseils en photographie, des inspirations pour vos séances photo, et les dernières tendances. Capturez vos moments avec expertise et passion."
    robotsContent="index, follow"
  />
)
